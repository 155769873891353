import React from "react";
// import PropTypes from "prop-types";
import Layout from '../Layout';

// import { SearchListItem } from "@civicactions/data-catalog-components";
import config from "../../assets/config";
// import './featureddatasets.scss';
import axios from "axios";
// import { normalizeItems } from '../../config/search'
import { Link } from "@reach/router";
import { Text } from "@civicactions/data-catalog-components";

const FeaturedCollectionsList = ({ type, location }) => {
  const [items, setFItems] = React.useState(null)

  React.useEffect(() => {
    async function getCollections() {
      const { data } = await axios.get(`${process.env.REACT_APP_ROOT_URL}/collections`)
      setFItems(data);
    }
    if (items == null) {
      getCollections();
    }


  }, [items]);
  return (
    <Layout title="Featured Collections" breadcrumbs={[{ text: "Collections", path: location.pathname }]}>
      <div className={`dc-page ${config.container}`}>
        <h1>Featured Collections</h1>
        <div>
          {items && items.map((item) => {
            console.log(item)
            return (
              <div class="dc-search-list-item" key={item.nid}>
                <h2><Link to={item.url}>{item.title}</Link>
                </h2>
                <div class="dc-item-description"><Text value={item && item.description} /></div>
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  );
};


export default FeaturedCollectionsList;
