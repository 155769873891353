import React from 'react';
import axios from 'axios';
// import { Link } from '@reach/router';
import { NavBar } from "@civicactions/data-catalog-components";
import DropMenu from './drop-menu';




const NavBarMenu = () => {
  const [menu, setMenu] = React.useState([]);
  React.useEffect(() => {
    async function getMenu(menu, cb) {
      let rootURL = process.env.REACT_APP_D9_URL;
      const { data } = await axios.get(`${rootURL}/api/menu_items/${menu}?_format=json`)
      cb(data)
    }
    if (menu.length === 0) {
      getMenu('main', setMenu);
    }
  }, [menu.length])

  return (
    <>
    <NavBar
      navItems={menu.map(item => (
        <>
          <a className='dropbtn' href={item.relative}>
            {item.title}
          </a>
          {item.below && <DropMenu title={item.title} items={item.below} itk={item.key} />}
        </>
      ))}
      customClasses='container'
    />
  
    </>
  );
}


export default NavBarMenu