import { Link } from '@reach/router';
import axios from 'axios';
import React, { useState } from 'react';
import {
    Button, Modal, ModalFooter,
    ModalHeader, ModalBody, Form, Row, Container
} from "reactstrap"
import Account from './account';

function Login(props) {
    const [loading, setLoading] = useState(false);
    const [loginForm, setLoginForm] = useState(true);
    const [modal, setModal] = React.useState(false);
    const [error, setError] = useState(null);
    const [isLoggedIn, setUserLogin] = useState(sessionStorage.getItem('user'));
    const enableLoginForm = () => {
        setLoginForm(true)
    }
    const enableSignUpForm = () => {
        setLoginForm(false)
    }

    // handle button click of login form
    const toggleModal = () => setModal(!modal);
    const handleLogin = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        setError(null);
        setLoading(true);
        axios.post(`${process.env.REACT_APP_D9_URL}/user/login?_format=json`, { name: data.get('name'), pass: data.get('password') }).then(response => {
            if (response.hasOwnProperty('data')) {
                setLoading(false);
                sessionStorage.setItem('user', JSON.stringify(response.data));
                sessionStorage.setItem('auth', window.btoa(data.get('name') + ':' + data.get('password')));
                setModal(false);
                setUserLogin(true);
            } else {
                setError("Something went wrong. Please try again later.");
            }
        }).catch(error => {
            // setRedirect(false);
            setLoading(false);
            if (error.response.data.message) setError(error.response.data.message);
            else setError("Something went wrong. Please try again later.");
        });
    }
    const handleRegister = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        if (data.get('password').length < 8) {
            setError("Password should have atleast 8 characters.");
            return;
        }

        setError(null);
        setLoading(true);
        const body = {
            name: [{ value: data.get('name') }],
            pass: [{ value: data.get('password') }],
            mail: [{ value: data.get('mail') }]
        }
        axios.post(`${process.env.REACT_APP_D9_URL}/user/register?_format=json`, body).then(response => {
            setLoading(false);
            setUserLogin(false);
            enableLoginForm();
        }).catch(error => {
            console.log(error);
            // setRedirect(false);
            setLoading(false);
            if (error.response.data.message) setError(error.response.data.message);
            else setError("Something went wrong. Please try again later.");
        });
    }

    const handleLogout = () => {
        // remove the token and user from the session storage and inform the server
        //@todo fix the server
        const user = JSON.parse(sessionStorage.getItem('user'));
        axios.post(`${process.env.REACT_APP_D9_URL}/user/logout?token=${user.logout_token}`, {}, { withCredentials: true, headers: { "X-CSRF-Token": user.csrf_token } }).then(response => {
            sessionStorage.removeItem('user');
            setUserLogin(false);
        }).catch(error => {
            // setRedirect(false);
            setLoading(false);
            if (error.response.data.message) setError(error.response.data.message);
            else setError("Something went wrong. Please try again later.");
        });
    }


    return (<>
        <div className='container-fluid col-lg-2' style={{marginTop:200}}>
            <Link to='/' >Home</Link>
            &nbsp;
            {isLoggedIn ? <>
                <Account />
                <Button color="primary" onClick={handleLogout}>Logout</Button>
            </>
                :
                <Button color="primary" size="lg" onClick={toggleModal}>LOGIN</Button>
            }
        </div>
        <div className='container-fluid col-lg-4'>
        <p> Note: For admin actions please login in <a href="/user/login" target='_blank'>Backend</a> too </p>
        </div>
        < Modal isOpen={modal} toggle={toggleModal} >
            <Form onSubmit={loginForm ? handleLogin : handleRegister}>
                <ModalHeader toggle={toggleModal}>
                    {loginForm ? "Login" : "Register"}
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Row sm="1" md="2">
                        </Row>
                    </Container>
                    <div style={{ marginLeft: "15px", marginRight: "15px" }}>
                        <div>
                            Username<br />
                            <input required="required" name="name" type="text" autoComplete="new-password" placeholder='Enter username' />
                        </div>
                        {!loginForm && (
                            <div style={{ marginTop: 10 }}>
                                Email<br />
                                <input required="required" name="mail" type="email" autoComplete="new-password" placeholder='Enter email address' />
                            </div>
                        )}
                        <div style={{ marginTop: 10 }}>
                            Password<br />
                            <input required="required" name="password" type="password" autoComplete="new-password" placeholder='Enter password' />
                        </div>
                        <div className='register-btn' onClick={() => !loginForm ? enableLoginForm() : enableSignUpForm()}>
                            <span style={{ color: '#000' }}>{!loginForm ? "Already have an account?" : "Do not have an account?"}</span> {!loginForm ? "Login here" : "Register here"}
                        </div>
                        {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />

                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="submit" disabled={loading} className="submit-btn">{loading ? 'Loading...' : "Submit"} </Button>
                </ModalFooter>
            </Form>
        </Modal >
    </>
    );
}

export default Login;