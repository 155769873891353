import axios from 'axios';
import React, { useState } from 'react';
import {
    Button, Modal, ModalFooter,
    ModalHeader, ModalBody, Form
} from "reactstrap"

function Account(props) {
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = React.useState(false);
    const [isLoggedIn, setUserLogin] = useState(sessionStorage.hasOwnProperty('user'));
    const [user, setUser] = useState({});
    const [error, setError] = useState(null);
    const rootURL = process.env.REACT_APP_D9_URL;


    // handle button click of login form
    const toggleModal = () => setModal(!modal);

    React.useEffect(() => {
        async function getData() {
            const userData = JSON.parse(sessionStorage.getItem('user'));
            const auth = sessionStorage.getItem('auth');
            const { data } = await axios.get(`${rootURL}/user/${userData.current_user["uid"]}?_format=json`, { headers: { "Authorization": "Basic " + auth } })
            console.log(data)
            setUser(data)
        }
        if (isLoggedIn) {
            getData();
        }
    }, [isLoggedIn, rootURL])

    const handleRegister = async (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        if(data.get('newPass').length < 8){
            setError("Password should have atleast 8 characters.");
            return;
        }
        setError(null);
        setLoading(true);
        const body = {
            name: [{ value: data.get('name') }],
            pass: [{ existing: data.get('currentPass'),value: data.get('newPass') }],
            mail: [{ value: data.get('mail') }]
        }
        const userData = JSON.parse(sessionStorage.getItem('user'));
        const auth = sessionStorage.getItem('auth');
        const { data: token } = await axios.get(`${rootURL}/session/token`,{withCredentials: true,"Authorization": "Basic " + auth});
        await axios.patch(`${rootURL}/user/${userData.current_user["uid"]}?_format=json`, body, {
            withCredentials: true,
            headers: {
                "X-CSRF-Token": token,
                "Authorization": "Basic " + auth
            }
        }).then(response => {
            setLoading(false);
            setUserLogin(false);
            sessionStorage.removeItem('auth');
            sessionStorage.removeItem('user');
            toggleModal()
            window.location.reload();
        }).catch(error => {
            console.log(error);
            // setRedirect(false);
            setLoading(false);
            if (error.response.data.message) setError(error.response.data.message);
            else setError("Something went wrong. Please try again later.");
        });
    }




    return (<>
        {isLoggedIn &&
            <Button color="primary" onClick={toggleModal}>My Account</Button>
        }
        {Object.keys(user).length &&
            < Modal isOpen={modal} toggle={toggleModal} >
                <Form onSubmit={handleRegister}>
                    <ModalHeader toggle={toggleModal}>
                    My Account
                    </ModalHeader>
                    <ModalBody>
                        <div style={{ marginLeft: "15px", marginRight: "15px" }}>
                            <div>
                                Username<br />
                                <input required="required" value={user.name[0].value} name="name" type="text" autoComplete="new-password" />
                            </div>
                            <div style={{ marginTop: 10 }}>
                                Email<br />
                                <input required="required" name="mail" defaultValue={user.mail[0].value} type="email" autoComplete="new-password" />
                            </div>
                            <div style={{ marginTop: 10 }}>
                                Current Password<br />
                                <input required="required" name="currentPass" type="password" autoComplete="new-password" />
                            </div>
                            <div style={{ marginTop: 10 }}>
                                New Password<br />
                                <input required="required" name="newPass" type="password" autoComplete="new-password" />
                            </div>
                            {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit" disabled={loading}  className="submit-btn">{loading ? 'Saving...' : 'Save'} </Button>
                    </ModalFooter>
                </Form>
            </Modal >
        }
    </>
    );
}

export default Account;