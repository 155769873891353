import React from "react";
import PropTypes from "prop-types";
// import excerpts from 'excerpts';
import { Link } from '@reach/router';
// import { Text } from "@civicactions/data-catalog-components";
// import config from "../../assets/config";
import './featureddatasets.scss';
import axios from "axios";
import Img from "../Image";
import { Row, Col} from "reactstrap"

const FeaturedDatasets = ({ isApi, title }) => {
  const [fDatasets, setFDatasets] = React.useState({})

  React.useEffect(() => {
    async function getDatasets() {
      const { data } = await axios.get(`${process.env.REACT_APP_ROOT_URL}/search?featured=1&facets=0&page-size=3&&sort=modified&sort-order=desc`)
      setFDatasets(data.results);
    }
    if (Object.keys(fDatasets).length === 0) {
      getDatasets();
    }


  }, [fDatasets]);

  if(Object.keys(fDatasets).length === 0) {
    return null;
  }

  return (
    <div className={`dc-featured`}>
      <h2 className="dc-featured-title" style={{ textAlign: "center", padding: "2rem 0"}}>{title}</h2>
      <Row className="featured-wrapper">
        {Object.keys(fDatasets).length > 0 ? Object.keys(fDatasets).map((index) => {
          const item = fDatasets[index];
          return (
            <Col xs="12" md="4" className="list-item-wrapper">
           
              < Link to={isApi ? `dataset/${item.identifier}/api` : item.url} key={item.identifier} className="list-link">
                <Img src={isApi && item.api_image ? item.api_image : item.image} alt={item.title} />
                <h3 style={{textAlign: "center"}}>{item.title}</h3>
              </Link>
              {/* <Text>
                {excerpts(item.description, { words: 35 })}
              </Text> */}
           </Col>
          )
        })
          :
          (<span>No Featured Datasets</span>)
        }
     </Row>  
      <Link to={`${isApi ? '/datasets/api' : '/search'}?featured=1&sort=modified&sort-order=desc`} className="view-all">View All {title}</Link>
    </div >
  );
};

FeaturedDatasets.propTypes = {
  datasets: PropTypes.arrayOf(PropTypes.object)
};

export default FeaturedDatasets;
