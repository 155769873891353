import React from 'react';

export const defaultFacets = {
  "publisher__name": {
    "label": "Departments",
    "field": "publisher__name",
    "showAll": false,
    "limit": 5,
    "reset": {
      "active": true,
      "icon": <span></span>
    },
  },
  "theme": {
    "label": "Sectors",
    "field": "theme.0.title",
    "showAll": false,
    "limit": 5,
    "reset": {
      "active": true,
      "icon": <span></span>
    },
  },
  "featured": {
    "label": "Featured",
    "field": "featured",
    "showAll": false,
    "limit": 5,
    "reset": {
      "active": false,
      "icon": <span></span>
    },
  },
  "collection": {
    "label": "Collection",
    "field": "collection",
    "showAll": false,
    "limit": 5,
    "reset": {
      "active": false,
      "icon": <span></span>
    },
  },
  "geospatial": {
    "label": "Geospatial",
    "field": "geospatial",
    "showAll": false,
    "limit": 5,
    "reset": {
      "active": true,
      "icon": <span></span>
    },
  },
  "keyword": {
    "label": "Tags",
    "field": "keyword.*.title",
    "showAll": false,
    "limit": 5,
    "reset": {
      "active": true,
      "icon": <span></span>
    },
  }
}

export function normalizeItems(resultItems) {
  let nItems = resultItems;
  if (!Array.isArray(nItems)) {
    nItems = Object.values(nItems);
  }
  return nItems.map((x) => {
    let item = {};
    item = {
      identifier: x.identifier,
      modified: x.modified,
      description: x.description,
      theme: x.theme,
      format: x.distribution,
      title: x.title,
      ref: x.url
    };
    if (
      Object.prototype.hasOwnProperty.call(x, "publisher") &&
      Object.prototype.hasOwnProperty.call(x.publisher, "name")
    ) {
      item.publisher = x.publisher.name;
    } else {
      item.publisher = "";
    }
    return item;
  });
}

export const sortOptions = [
  {
    field: 'modified',
    order: 'desc',
    label: 'Date'
  },
  {
    field: 'title',
    order: 'asc',
    label: 'Name'
  }
];
