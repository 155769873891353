import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import { TopicIcon } from '@civicactions/data-catalog-components';

function ListItem({
  image, link, title, size, color, identifier,
}) {
  let content = '';

  if (image) {
    // Image provided as a url.
    content = (
      <Link to={link} className="dc-icon-link">
        <img src={image} alt={title} />
        <div>{title}</div>
      </Link>
    );
  } else {
    // Image provided by custom component.
    content = (
      <Link to={link} className="dc-icon-link">
        <TopicIcon
          title={title}
          size={size}
          fill={color}
        />
        <div>{title}</div>
      </Link>
    );
  }

  return (
    <div key={identifier} className="item-container">{content}</div>
  );
}

ListItem.defaultProps = {
  link: '',
  image: '',
  size: '',
  color: '',
  identifier: '',
};

ListItem.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  image: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  identifier: PropTypes.string,
};

export default ListItem;
