import React from "react";
// import PropTypes from "prop-types";
import Layout from '../Layout';

import { SearchListItem } from "@civicactions/data-catalog-components";
import config from "../../assets/config";
// import './featureddatasets.scss';
import axios from "axios";
import { normalizeItems } from '../../config/search'

const Featured = ({ type }) => {
  const [fDatasets, setFDatasets] = React.useState(null)

  React.useEffect(() => {
    async function getDatasets() {
      const { data } = await axios.get(`${process.env.REACT_APP_ROOT_URL}/search?featured=1&facets=0&page-size=3&&sort=modified&sort-order=desc`)
      setFDatasets(normalizeItems(data.results));
    }
    if (fDatasets == null) {
      getDatasets();
    }


  }, [fDatasets]);

  if(Object.keys(fDatasets).length === 0) {
    return null;
  }
  
  return (
    <Layout title="Featured Datasets">
      <div className={`dc-page ${config.container}`}>
        <h1>Featured Datasets</h1>
        <div>

          {fDatasets && Object.keys(fDatasets).map((index) => {
            if (type === 'api') {
              fDatasets[index].ref = '/dataset/' + fDatasets[index].identifier + '/api'
            }
            return (
              <SearchListItem item={fDatasets[index]} key={index} />
            )
          })}

        </div>
      </div>
    </Layout>
  );
};


export default Featured;
