import React from 'react';
import Helmet from "react-helmet";
import Header from '../Header'
import Footer from '../Footer'
import config from "../../assets/config.json";
// import links from "../../assets/menu.json";
import NavBarMenu from '../NavBarMenu'
import Breadcrumb from '../Breadcrumb';

const Layout = ({
  children,
  title,
  description,
  breadcrumbs,
}) => {
  return (
    <div className="App">
      <Helmet
        title={`${title} - Open Data Telangana`}
        description={description}
        // generator="DKAN 2 (https://github.com/GetDKAN/dkan)"
        defer={false}
        htmlAttributes={{
          "lang": "en"
        }}
      />
      <Header rlogo={config.rlogo} logo={config.logo} site={config.site} slogan={config.slogan} customClasses={config.container} />
      <NavBarMenu />
      <Breadcrumb items={breadcrumbs} />
      <main>
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
