import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
// import { Link } from '@reach/router';
import { Menu } from "@civicactions/data-catalog-components";

function Footer({
  links, customClasses, rlogo
}) {

  const [footer1, setFooter1] = React.useState([]);
  const [footer2, setFooter2] = React.useState([]);

  React.useEffect(() => {
    async function getMenus(menu, cb) {
      let rootURL = process.env.REACT_APP_D9_URL;
      const { data } = await axios.get(`${rootURL}/api/menu_items/${menu}?_format=json`)
      cb(data.map((a) => { return { label: a.title, url: a.relative, target: '_blank' } }))
    }
    if (footer1.length === 0) {
      getMenus('footer-left', setFooter1);
      getMenus('footer-right', setFooter2);
    }
  }, [footer1])


  // const menu1 = links ?  : null;
  // const menu2 = links ? <Menu items={links.footer2} menuId="rightnav" /> : null;

  return (
    <div className="dc-footer">
      <div className={`${customClasses} container page-footer`}>
        <Menu items={footer1} menuId="leftnav" />
        <Menu items={footer2} menuId="rightnav" />
        {/* {menu2} */}
        <div className="footer-right col-sm-3">
          {/* <a href="http://www.it.telangana.gov.in/digital-telangana-program" target="_blank" rel="noopener noreferrer">
            <img alt="Digital Telangana" src="/frontend/build/logos/digital_telangana.png" />
          </a> */}
          <a href="http://www.telangana.gov.in/" target="_blank" rel="noopener noreferrer" style={{float:'right'}}>
            <img alt="Government of Telangana" src="/frontend/build/logos/telangana_state_logo-02.png" width={100} height={100} />
          </a>
          {/* <a href="https://data.gov.in" target="_blank" rel="noopener noreferrer">
            <img alt="Government of Telangana" src="/frontend/build/logos/data_gov.png" />
          </a> */}
        </div>
      </div>
      <div className={`${customClasses} container page-footer`}>
        <div className='col-sm-12' style={{ 'fontSize': "80%", 'textAlign': "center" }}>
          <a href='https://it.telangana.gov.in/' target='_blank' rel="noopener noreferrer" >© Copyright @ {new Date().getFullYear()}. Open Data Unit, Information Technology, Electronics &amp; Communication Department (IT E&amp;C), Government Of Telangana. All rights reserved.</a>
        </div>
      </div>
    </div>
  );
}

Footer.defaultProps = {
  customClasses: '',
  links: null,
};

Footer.propTypes = {
  customClasses: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
  })),
};

export default Footer;