import React, { useEffect, useState } from 'react';
import config from "../../assets/config";
import Layout from '../../components/Layout';
import axios from 'axios';
import { SearchListItem, Text } from '@civicactions/data-catalog-components';
import { normalizeItems } from '../../config/search';


const Collections = ({ slug, location }) => {
  const { pathname } = location
  const [item, setItem] = useState(null);
  useEffect(() => {
    async function getCollection() {
      const { data: url } = await axios.get(`${process.env.REACT_APP_D9_URL}/local-path?alias=${pathname}`);

      const id = url.path.split('/').slice(2);
      const { data } = await axios.get(`${process.env.REACT_APP_ROOT_URL}/collection/${id}`);
      data.datasets = normalizeItems(data.datasets);
      setItem(data);
    }
    if (item == null) {
      getCollection();
    }

  }, [item, pathname]);
  const userData = JSON.parse(sessionStorage.getItem('user'));
  const isEditor = userData?.current_user?.roles && userData?.current_user?.roles.includes('administrator');
  return (
    <Layout title="Dataset Collection" breadcrumbs={[{ text: "Collections", path: "/featured/collection" }, { text: item ? item.title : '', path: pathname }]}>
      <div className={`dc-page ${config.container}`}>
        <h1>{item && item.title}
          {isEditor && item && item.title && (<i style={{float:"right"}}>
          <a style={{color:"black"}} target='_blank' rel="noopener noreferrer" href={`${process.env.REACT_APP_D9_URL}/admin/content?title=${item.title}&type=collection&status=All&langcode=All`}> Edit</a></i>)}
        </h1>
        <div>
          <Text value={item && item.description} />

          {item && item.datasets.map((dataset, index) => {
            return (
              <SearchListItem item={dataset} key={index} />
            )
          })}

        </div>
      </div>
    </Layout>
  );
}

export default Collections;
