import React from 'react';
import PropTypes from 'prop-types';
import { FormatIcon, Text } from '@civicactions/data-catalog-components';
import { FaRegChartBar } from 'react-icons/fa';
import FileDownloadModal from './modal';
// import ReactGA from 'react-ga';

const FileDownload = ({
  title, format, downloadURL, description, identifier, expandTable, isExpanded
}) => {


  const label = title || format;
  // const rootURL = `${process.env.REACT_APP_D9_URL}`;

  // const eventTrack = (category, action, label) => {
  //   console.log("GA event:", category, ":", action, ":", label);
  //   ReactGA.event({
  //     category: category,
  //     action: action,
  //     label: label,
  //   })
  // }
  //onClick={eventTrack.bind(this, "Metrics", "download", "Download")}

  // const d9RootURL = `${process.env.REACT_APP_D9_URL}`;
  const item = (
    <div className="dc-resource">
      <FormatIcon format={format} />
      {/* <a href={downloadURL} title={label}>
        {label}
      </a> */}
      <FileDownloadModal key={1} downloadURL={downloadURL} label={label} filename={`${label}.csv`} />
      {format === 'csv' && (
        <div className='btns-group'>
          <button className="btn btn-primary csv-download" onClick={expandTable}> <FaRegChartBar /> {isExpanded ? "Collapse Preview" : "Preview"}</button>
          <FileDownloadModal key={2} downloadURL={downloadURL} label={'Download'} filename={`${label}.csv`} />
          {/* <FileDownloadModal key={2} downloadURL={`${d9RootURL}/resource/${identifier}/download/xlsx`} label={'Download'} filename={`${label}.xlsx`} /> */}
          {/* <a className='btn btn-primary' style={{ 'marginLeft': 10, 'line-height': 'inherit' }} href={`${d9RootURL}/resource/${identifier}/download/xlsx`}>
          <FaDownload />
          &nbsp; Download
        </a> */}
        </div>
      )}
      {description && (
        <Text value={description} wrapper={{ tag: 'div', classes: 'dc-file-description' }} />
      )}

    </div>
  );

  return (
    <div className="dc-file-download">
      {item}
    </div>
  );
};

FileDownload.defaultProps = {
  description: '',
  title: '',
};

FileDownload.propTypes = {
  title: PropTypes.string,
  format: PropTypes.string.isRequired,
  downloadURL: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default FileDownload;
