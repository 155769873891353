// import { Redirect } from "@reach/router";
import React, { useEffect, useState } from "react";
// import ReactGA from 'react-ga';
import axios from 'axios';
// import fileDownload from 'js-file-download'
import "./style.scss";

import {
    Button, Modal, ModalFooter,
    ModalHeader, ModalBody, Form, FormGroup, Col
} from "reactstrap"

let formData = { email: null, name: null, purpose: "", type: null };

const FileDownloadModal = ({ downloadURL, label, filename }) => {
    // const [isFormValid, setValidForm] = useState(false)
    const [modal, setModal] = React.useState(false);
    const [error, setError] = useState(null);
    const [user, setUser] = useState({ name: '', mail: '' });
    const [captchaId, setCaptchaId] = useState('');
    const [captchaImage, setCaptchaImage] = useState('');
    const [captchaText, setCaptchaText] = useState('');

    const fetchCaptcha = async () => {
        const {data} = await axios.post(`${process.env.REACT_APP_D9_URL}/api/captcha`);
        setCaptchaId(data.captcha_id);
        setCaptchaImage(data.captcha_image);
    };
    // const [isLoggedIn, setUserLogin] = useState(sessionStorage.hasOwnProperty('user'));
    // Toggle for Modal
    const validateAndDownload = async (e) => {
        e.preventDefault();
        setError(null);
        const data = new FormData(e.target);
        for (const [key, value] of data) {
            formData[key] = value;
        }

        const { data: token } = await axios.get(`${process.env.REACT_APP_D9_URL}/session/token`, { withCredentials: true, });
        axios.post(`${process.env.REACT_APP_D9_URL}/node?_format=json`, {
            "title": [{ "value": filename }],
            "field_email": [{ "value": formData.email }],
            "field_name": [{ "value": formData.name }],
            "field_purpose": [{ "value": formData.purpose }],
            "field_usage_type": [{ "value": formData.type }],
            "field_file_url": [{ "value": downloadURL }],
            "type": [{ "target_id": "download" }]
        }, {
            withCredentials: true,
            headers: {
                'X-Captcha-Id': captchaId,
                'X-Captcha-Text': captchaText,
            }
        })
            .then(function (response) {
                window.open(downloadURL,'_blank','noreferrer');
                toggleModal()
                // axios.get(downloadURL, {
                //     responseType: 'blob',
                //     withCredentials: true
                // }).then(res => {
                //     fileDownload(res.data, filename);
                //     toggleModal()
                // })
            })
            .catch(function (error) {
                // console.log(error);
                if (error?.response?.message) setError(error.response.message);
                else setError("Something went wrong. Please try again with valid inputs.");
                setCaptchaText('')
                fetchCaptcha()
            });
        // ReactGA.event({
        //     category: "Metrics",
        //     action: 'Download',
        //     label: label,
        // }, {
        //     dimension1: formData.name,
        //     dimension2: formData.email,
        //     dimension3: formData.purpose,
        //     dimension4: formData.type
        // });
        // setValidForm(true)

    }
    const toggleModal = () => {
        if(!modal) {
            fetchCaptcha()
        }
        setModal(!modal);
    }

    React.useEffect(() => {
        async function getData() {
            const userData = JSON.parse(sessionStorage.getItem('user'));
            const auth = sessionStorage.getItem('auth');
            const { data } = await axios.get(`${process.env.REACT_APP_D9_URL}/user/${userData.current_user["uid"]}?_format=json`, { headers: { "Authorization": "Basic " + auth } })
            console.log(data)
            setUser({ name: data.name[0].value, mail: data.mail[0].value });
        }
        if (sessionStorage.hasOwnProperty('user')) {
            getData();
        }
    }, [])
    return (<>
        <Button color="primary" onClick={toggleModal}>{label}</Button>
        <Modal isOpen={modal} toggle={toggleModal}>
            <Form onSubmit={validateAndDownload}>
                <ModalHeader
                    toggle={toggleModal}>Please fill in the following information to download the dataset</ModalHeader>
                <ModalBody>
                    <div style={{ marginLeft: "15px", marginRight: "15px" }}>
                        <FormGroup row>
                            <Col sm={10}>
                                Name<sup class="star">*</sup><br />
                                <input id="name" required defaultValue={user.name} name="name" placeholder="Name" type="text" />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col sm={10}>
                                 Email<sup class="star">*</sup><br />
                                <input id="email" required defaultValue={user.mail} name="email" placeholder="Email!" type="email" />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col sm={10}>
                                 Purpose<sup class="star">*</sup><br />
                                <input id="purpose" required name="purpose" placeholder="Please enter the purpose!" type="text" />
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                         
                            <Col sm={10}>
                            <label>Usage Type<sup class="star">*</sup></label>
                                <div className="radio-btn">
                                <input id="type" name="type" required value={"commercial"} type="radio" />
                                <label>Commercial</label>
                                </div>
                                {" "}
                               
                                <div className="radio-btn">
                                <input id="type" name="type" required value={"non-commercial"} type="radio" />
                                <label>Non-comercial</label>
                                {" "}
                            
                                </div>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col sm={10}>
                                Please enter the text below<sup class="star">*</sup>
                                <img src={`data:image/png;base64,${captchaImage}`} alt="CAPTCHA" />
                                <br />
                                <input
                                    type="text"
                                    name="captcha_text"
                                    value={captchaText}
                                    onChange={(e) => setCaptchaText(e.target.value)}
                                    required
                                    />
                            </Col>
                        </FormGroup>
                        {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="submit" className="submit-btn">Download</Button>
                    <Button color="primary" onClick={toggleModal} className="submit-btn">Cancel</Button>
                </ModalFooter>
            </Form>
        </Modal>
    </>
    );
}

export default FileDownloadModal