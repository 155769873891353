import React from 'react';
import { Link } from '@reach/router';
import "../Header/high_contrast.scss"
// import Login from "../User/login"
import FontSizeChanger from 'react-font-size-changer';
import {FaWheelchair} from 'react-icons/fa'

const Header = ({
  link, logo, site, slogan, customClasses, rlogo,
}) => {


  // const [isStyleContrast, setContrastStyle] = useState(false);
  const enableContrasting = () => {
    document.body.classList.add('high-contrast');
    // setContrastStyle(true);
  };
  const disableContrasting = () => {
    document.body.classList.remove('high-contrast');
    // setContrastStyle(false);
  };

  return (
    <div className="dc-header">
      <div className='container' style={{background:'#cccccc'}}>
        <div style={{float: 'right'}}>
          <div className='btn-wrapper'>
            <a href='/screen-reader' ><FaWheelchair/></a>
          </div>
          <div className='high-contrast-wrapper'>
            <div className='btn-wrapper contrasted'>
              <a className='' onClick={disableContrasting}>A</a>
            </div>
            <div className='btn-wrapper active'>
              <a className='' onClick={enableContrasting}>A</a>
            </div>
          </div>
          <FontSizeChanger
            targets={['.App .dc-main-navigation','.App main','.App .dc-footer']}
            options={{
              stepSize: 2,
              range: 5
            }}
            customButtons={{
              up: <a className=''>A<sup>+</sup></a>,
              down: <a className=''>A<sup>-</sup></a>,
              style: {
                width: '30px',
                height: '30px',
                lineHeight: '30px',
              //   cursor: 'pointer'
                border: '1px solid #888888',
                borderTop: 0,
                // borderRight: 0,
                background: '#cccccc',
                fontSize: '1.5rem'
              },
              buttonsMargin: 0
            }}
            />
          </div>
        </div>
      <div className={customClasses}>
        <div className="branding">
          <Link to={link} className="dc-logo">
            <img src={logo} width={120} height={120} alt="Open Data Catalog" />
          </Link>
          <div className="dc-site-name">
            {site
              && <Link to={link}>{site}</Link>}
            {slogan
              && (
                <div className="dc-slogan">
                  {slogan}
                </div>
              )}
          </div>
          <a href='https://telangana.gov.in/' target='_blank' rel="noopener noreferrer" className="dc-logo" style={{ float: 'right' }}>
            <img src={rlogo} width={120} height={120} alt="Open Data Catalog" />
          </a>
        </div>
      </div>
    </div>
  )
};

Header.defaultProps = {
  customClasses: null,
  link: '/',
  logo: '',
  site: '',
  slogan: '',
  rlogo: ''
};


export default Header;
