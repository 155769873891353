import React from 'react'

const Img = (props) => {
  const src = props.src ? props.src : "/frontend/build/default-img.jpg";
  
  // console.log(props);
  return (
    <img src={src} alt={props.alt} />
  );
}

export default Img