import React, { useState } from "react";
import '../NavBarMenu/style.scss'
// import { sectors } from '../../assets/sectors.json'
import axios from "axios";

const DropMenu = ({ items, title }) => {

  const [themes, setThemes] = useState([])
  async function getThemes() {
    const { data } = await axios.get(`${process.env.REACT_APP_ROOT_URL}/metastore/schemas/theme/items`)
    setThemes(data);
  }

  if (title === 'Sectors' && themes.length === 0) {
    getThemes()
  }


  items = items.map((item) => {

    if (item.options.query) {
      const queryString = Object.keys(item.options.query).map(key => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(item.options.query[key])
      }).join('&');
      item.url = item.relative + '?' + queryString;
    }
    themes.forEach((x, i) => {
      if (x.data === item.title && x.image) {
        item.image = x.image
      }
    });
    // let mat = themes.find(x => x.data === item.title && x.image);
    return item;
  })
  return (
    <div className={`dropdown-content ${items.length > 5 ? "multi-col" : "single-col"}`}>
      {items.map(item => (
        <a key={item.key} href={item.url}///url(/profiles/dkan/themes/nuboot_radix/assets/images/law-order-small-icon.png) 9px 9px no-repeat
        >
          {item.image ? <img src={item.image} alt={item.title} className="side-img" /> : <div className={title === 'Sectors' ? "side-img" : ""}/>}<span>{item.title}</span>
        </a>
      ))}
    </div>
  )
};

export default DropMenu;