import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "@reach/router";

const Breadcrumb = ({ items }) => {

  if (!items || items.length === 0) {
    return (<></>);
  }

  return (
    <div className="" style={{ backgroundColor: '#e9ecef' }}>
      <ul className="breadcrumb container" style={{ paddingLeft: '30px' }}>
        <li key ="100" className="home-link">
          <Link to="/" title="Home">
            <FontAwesomeIcon
              icon={['fas', 'home']}
              size="1x"
            />
          </Link>
        </li>
        {items && items.map((item, i) => {
          return (
            <>
              &nbsp;/&nbsp;
              {i + 1 === items.length ? (
                <li key={i} className="active-trail">
                  <span>{item.text}</span>
                </li>
              ) : (
                <li key={i}>
                  <Link to={item.path}>{item.text}</Link>
                </li>
              )}
            </>
          )
        })}
      </ul>
    </div>
  );
};

export default Breadcrumb;