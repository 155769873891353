import React from "react";

import {
    FacebookShareButton,
    LinkedinShareButton,
    RedditShareButton,
    TwitterShareButton,
    FacebookIcon,
    LinkedinIcon,
    RedditIcon,
    TwitterIcon,
} from "react-share";
import './styles.scss';

const SocialShare = ({ url }) => {
    // const components = [FacebookShareButton, LinkedinShareButton, TwitterShareButton, RedditShareButton]
    return (
        <div className="social-share">
            <ul>
                <li className="first-child">
                    Social
                </li>
                <li>
                    <FacebookShareButton url={url} >
                        <FacebookIcon size={30} /> Facebook
                    </FacebookShareButton>
                </li>
                <li>
                    <LinkedinShareButton url={url}>
                        <LinkedinIcon size={30} /> LinkedIn
                    </LinkedinShareButton>
                </li>
                <li>
                    <TwitterShareButton url={url} >
                        <TwitterIcon size={30} /> Twitter
                    </TwitterShareButton>
                </li>
                <li>
                    <RedditShareButton url={url}>
                        <RedditIcon size={30} /> Reddit
                    </RedditShareButton>
                </li>
            </ul>
        </div>
    );
};

export default SocialShare;