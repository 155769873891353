// import { IconList, IconListItem } from "@civicactions/data-catalog-components";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap"
import ListItem from "./listItem";

const Sectors = ({ items }) => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 768;
  const showLimit = isMobile ? 3 : 12;
  const [showAll, setShowAll] = useState(false);

  const handleShowAll = () => {
    setShowAll(!showAll);
  }

  const displayItems = showAll ? items : items.slice(0, showLimit);

  return (
    <div className="dc-icon-link-wrapper">
       <div className={`dc-icon-list`}>
        <h2 className="pane-title">Sectors</h2>
        <Row>
      {displayItems.map( item => <Col xs="12" md="3" lg="2"  className="list-item-wrapper"><ListItem image={item.image} link={item.ref} title={item.title} size={item.size} color={item.color} identifier={item.id} /></Col>)}
      </Row>
      </div>
    
      {items.length > showLimit && <a href="javaScript:void(0)" style={{ float: 'right' }} className="view-all pull-right" onClick={handleShowAll}>{showAll ? 'Less' : 'More'} Sectors</a>}
    </div>

  );

}

export default Sectors;