import React from "react";
// import PropTypes from "prop-types";
// import excerpts from 'excerpts';
import { Link } from '@reach/router';
// import { Text } from "@civicactions/data-catalog-components";
// import config from "../../assets/config";
import '../FeaturedDatasets/featureddatasets.scss';
import axios from "axios";
import Img from '../Image'
import { Row, Col} from "reactstrap"


const FeaturedCollections = () => {
  const [items, setItems] = React.useState([])

  React.useEffect(() => {
    async function getCollections() {
      const { data } = await axios.get(`${process.env.REACT_APP_D9_URL}/api/1/collections?featured=1&items_per_page=3`)
      setItems(data);
    }
    if (items.length === 0) {
      getCollections();
    }


  }, [items.length]);

  if(!items.length && items.length === 0) {
    return null;
  }
  return (
    <div className={`dc-featured`}>
      <h2 className="dc-featured-title" style={{ textAlign: "center", padding: "2rem 0"}}>Featured Collections</h2>
      <Row className="featured-wrapper">
        {items.length !== 0 ? items.map((item) => (
           <Col xs="12" md="4" className="list-item-wrapper">
            <Link to={item.url} key={item.nid} className="list-link">
              <Img src={item.image} alt={item.title} />
              <h3>{item.title}</h3>
            </Link>
            {/* <Text>
              {excerpts(item.description, { words: 35 })}
            </Text> */}
          </Col>
        )) : (<span>No Featured Collections</span>)}
      </Row>
      <Link to="/featured/collection" className="view-all">View All Collections</Link>
    </div>
  );
};

// FeaturedAPI.propTypes = {
//   datasets: PropTypes.arrayOf(PropTypes.object)
// };

export default FeaturedCollections;
