import React from 'react';
import { useLocation } from "@reach/router"
import { defaultFacets, sortOptions } from '../../config/search';
import { Search, SearchSidebar, SearchContent } from "@civicactions/data-catalog-components";
import Layout from "../../components/Layout";
import config from "../../assets/config";
import '../search/search.scss';

const SearchApi = ({ path }) => {
  const location = useLocation();
  const normalizeItems = (resultItems) => {
    let nItems = resultItems;
    if (!Array.isArray(nItems)) {
      nItems = Object.values(nItems);
    }
    return nItems.map((x) => {
      let item = {};
      item = {
        identifier: x.identifier,
        title: x.title,
        ref: `/dataset/${x.identifier}/api`
      };
      return item;
    });
  };
  return (
    <Layout title="Search" breadcrumbs={[{text:"Datasets API", path:"/datasets/api"}]}>
      <div className={`dc-page ${config.container}`}>
        <h1>Datasets</h1>
        <Search
          searchEndpoint={`${process.env.REACT_APP_ROOT_URL}/search`}
          defaultFacets={defaultFacets}
          sortOptions={sortOptions}
          setSearchUrl={true}
          path={path}
          location={location}
          normalize={normalizeItems}
        >
          <div className="row">
            <SearchSidebar />
            <SearchContent />
          </div>
        </Search>
      </div>
    </Layout>
  );
}

export default SearchApi;
