import React from "react";
import axios from 'axios';
import {
  // Blocks,
  Hero,
  // Hero,
  // IconList,
  // IconListItem,
  StatBlock
} from "@civicactions/data-catalog-components";
import Layout from '../../components/Layout';
import FeaturedDatasets from '../../components/FeaturedDatasets';
// import FeaturedAPI from '../../components/FeaturedAPI';
// import ImageSlider from '../../components/ImageSlider';
import FeaturedCollections from "../../components/FeaturedCollections";
import Sectors from "./sectors";
// import copy from "../../assets/copy.json";

const Home = () => {
  // const [datasets, setDatasets] = React.useState(null);
  const [themes, setThemes] = React.useState(null);
  const [items, setItems] = React.useState([]);
  const [stats, setStats] = React.useState([]);


  React.useEffect(() => {
    // async function getDatasets() {
    //   const {data} = await axios.get(`${process.env.REACT_APP_ROOT_URL}/metastore/schemas/dataset/items?show-reference-ids`)
    //   setDatasets(data);
    // }
    async function getThemes() {
      const { data } = await axios.get(`${process.env.REACT_APP_ROOT_URL}/homepage/theme/items`)
      setThemes(data);
    }
    async function getStats() {
      const { data } = await axios.get(`${process.env.REACT_APP_ROOT_URL}/stats`)
      setStats(data);
    }
    if (themes === null) {
      // getDatasets()
      getThemes();
    }
    if (stats.length === 0) {
      getStats();
    }
  }, [themes, stats.length])

  React.useEffect(() => {
    themes && setItems(themes.map(x => {
      let item = {
        identifier: x.identifier,
        ref: `search?theme=${x.data}`,
        title: x.data,
        size: "100",
        image: x.image?x.image:'/frontend/build/default-img.jpg'
      };

      return item;
    }))
  }, [themes])

  return (
    <Layout title="Home">
      <div className="home-page">
        {/* <ImageSlider /> */}
        {/* <Blocks
            items={stats}
            component={StatBlock}
            containerClass=""
            blockClass="StatBlock"
        /> */}
        <Hero items={stats} component={StatBlock} containerClass=""
            blockClass="StatBlock" title="Open Data Telangana" intro="Please enter the search keywords or text" gradient='rgb(250 223 223),#ffffff' />
        <Sectors items={items}/>
        <FeaturedDatasets title="Featured Datasets" />
        <FeaturedCollections />
        <FeaturedDatasets isApi={true} title="Featured API" />
        {/* <FeaturedAPI /> */}
      </div>
    </Layout>
  );
}

export default Home;
