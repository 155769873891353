import React from 'react';
import { createHistory, LocationProvider, Router } from "@reach/router";
import Home from './templates/home';
import About from './templates/about';
import SearchTemplate from './templates/search';
import ApiDocsFull from './templates/api';
// import NotFound from './templates/not_found';
import Dataset from './templates/dataset';
import ApiDocsSpecific from './templates/dataset/api';
import Publishers from './templates/publishers';
import Collections from './templates/collections';
import Featured from './components/Featured';
import '@civicactions/data-catalog-components/dist/index.css';
import './theme/index.scss';
import ReactGA from 'react-ga';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import FeaturedCollectionsList from './components/Featured/collections';
import SearchApi from './templates/search/api';
import Page from './templates/page';
import Login from './components/User/login'

library.add(fab, fas);

const trackingId = process.env.REACT_APP_GA_TRACKING_ID; // "UA-136165573-1"; // Replace with your Google Analytics tracking ID
const gaDebug = false;
const history = createHistory(window);


function App() {
  let gaInitialized = 0;

  async function getUser() {
    ReactGA.initialize(trackingId, {
      debug: gaDebug
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
    gaInitialized = 1;
  }
  if (!gaInitialized) {
    getUser();
  }
  history.listen(({ location }) => {
    ReactGA.pageview(location.pathname + location.search);
    // console.log('page=>', location.pathname);
  });


  return (
    <LocationProvider history={history}>
      <Router>
        <Page default />
        <Home path="/" />
        <About path="/about" />
        <Publishers path="/publishers" />
        <SearchTemplate path="/search" />
        <ApiDocsFull path="/api" />
        <Dataset path="/dataset/:slug" />
        <SearchApi path="/datasets/api" />
        <ApiDocsSpecific path="/dataset/:id/api" />
        <Collections path="/collection/:slug" />
        <Featured path="/featured/:type" />
        <FeaturedCollectionsList path="/featured/collection" />
        <Login path="/frontend/login" />
      </Router>
    </LocationProvider>
  );
}

export default App;
