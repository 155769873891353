import React, { useState } from "react";
import {
  Resource,
  DataTable,
  DataTableHeader
} from "@civicactions/data-catalog-components";
import FileDownload from '../FileDownload';
// import { Link } from "@reach/router";
import "../Resource/resource.scss"
// import { FaRegChartBar, FaDownload } from "react-icons/fa";

const ResourceTemplate = ({ resource }) => {
  const type = resource.hasOwnProperty('data') && resource.data.hasOwnProperty('mediaType') ? resource.data.mediaType.split("/") : '';
  const backup = type ? type[1] : 'data';
  const format = resource.hasOwnProperty('data') && resource.data.hasOwnProperty('format') ? resource.data.format : backup;

  const downloadURL = resource.hasOwnProperty('data') && resource.data.hasOwnProperty('downloadURL') ? resource.data.downloadURL : '';
  const accessURL = resource.hasOwnProperty('data') && resource.data.hasOwnProperty('accessURL') ? resource.data.accessURL : downloadURL;
  const rootURL = `${process.env.REACT_APP_ROOT_URL}/`;
  const [isExpanded, setExpanded] = useState(false)
  const expandTable = () => { setExpanded(!isExpanded) };


  return (
    <div id={`resource_${resource.identifier}`} className="dc-resource">

      {format.toLowerCase() === 'csv' && isExpanded
        ? (
          <Resource
            apiURL={rootURL}
            identifier={resource.identifier}
            resource={resource}
            showDBColumnNames={true}
          >
            <FileDownload
              title={resource.data.title}
              label={resource.data.downloadURL}
              format={format}
              downloadURL={downloadURL ? downloadURL : accessURL}
              identifier={resource.identifier}
              expandTable={expandTable}
              isExpanded={isExpanded}
            />
            <DataTableHeader />
            <DataTable />
          </Resource>
        )
        : (
          <FileDownload
            title={resource.data.title}
            label={resource.data.downloadURL}
            format={format}
            downloadURL={resource.data.downloadURL}
            identifier={resource.identifier}
            expandTable={expandTable}
            isExpanded={isExpanded}
          />
        )
      }

    </div>
  );
};

export default ResourceTemplate;
